export const postVote = async ({ title, upvoted }) => {
  const url = `${process.env.GATSBY_API_URL}/votes/${title}/${
    upvoted ? "increment" : "decrement"
  }`
  const response = await fetch(url, { method: "POST" })
  if (!response?.status === 200) {
    return true
  }
  return false
}

export const postFeedback = async ({ feedback, title }) => {
  if (!feedback || !title || !feedback.personType) {
    return false
  }

  const hours = parseInt(feedback.hours)
  const minutes = parseInt(feedback.minutes)
  const duration = hours * 60 + minutes

  const data = {
    title,
    duration,
    personType: feedback.personType,
    liked: feedback.liked,
    disliked: feedback.disliked,
    improvements: feedback.improvements,
    wishes: feedback.wishes,
  }

  const url = `${process.env.GATSBY_API_URL}/feedback`
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
  if (!response?.status === 200) {
    return true
  }
  return false
}
