/** @jsx jsx */
import React, { useState, useEffect } from "react" // eslint-disable-line no-unused-vars
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { useThemeUI, useColorMode } from "theme-ui"
import Box from "./box"
import Stack from "./stack"
import Columns from "./columns"
import Column from "./column"
import Divider from "./divider"
import Grid from "./grid"
import Text from "./text"

import stampDoneLight from "../images/kurse/stamp-done-light.svg"
import stampDone from "../images/kurse/stamp-done.svg"

import wavesLight from "../images/kurse/waves-light.svg"
import waves from "../images/kurse/waves.svg"
import { alpha } from "@theme-ui/color"

const TypewriterHeading = ({ children }) => {
  return (
    <Box
      as="p"
      sx={{
        fontFamily: "mono",
        color: "heading",
        fontSize: [3, 3, 4, 5],
      }}
    >
      {children}
    </Box>
  )
}

const CourseSummary = ({ courseTitle, totalChapters, totalTasks }) => {
  const context = useThemeUI()
  const { theme } = context
  const [colorMode] = useColorMode()

  const [state, setState] = useState({
    readyToAnimate: false,
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setState({
        readyToAnimate: true,
      })
    }, 800)
    return () => clearTimeout(timer)
  }, [])

  const currentDate = new Date().toLocaleDateString("de-DE", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <Box
      sx={{
        bg: "letter",
        backgroundImage: `url(${colorMode === "light" ? wavesLight : waves})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        p: [6, 12],
        borderRadius: "lg",
      }}
    >
      <Stack>
        <Columns
          collapse={[true, false]}
          reverse={[false, true]}
          space={[8, 12]}
        >
          <Column width="content">
            <Box
              aria-hidden="true"
              width={[16, 24, 24, 32]}
              height={[16, 24, 24, 32]}
              display="flex"
              alignY="center"
              alignX="center"
              textAlign="center"
              position="relative"
              ml={[3, "none"]}
            >
              <Box
                position="absolute"
                left={[-6, -6, -12]}
                top={[-6, -6, -12]}
                bottom={[-6, -6, -12]}
                right={[-6, -6, -12]}
                display="flex"
                alignY="center"
                alignX="center"
              >
                <img
                  src={colorMode === "light" ? stampDoneLight : stampDone}
                  alt={"Stempel mit der Aufschrift Erledigt"}
                  width="200"
                  height="162"
                  sx={{
                    pointerEvents: "none",
                    transform: state.readyToAnimate ? "scale(1)" : "scale(3)",
                    opacity: state.readyToAnimate ? 1 : 0,
                    transition: `transform ${theme.transitions["fast"]}, opacity ${theme.transitions["fast"]}`,
                  }}
                />
              </Box>
            </Box>
          </Column>
          <Column>
            <Box pr={6}>
              <Stack space={6}>
                <Stack space={2}>
                  <TypewriterHeading>{courseTitle}</TypewriterHeading>
                  <Divider size={2} color={alpha("contrast", 0.2)} />
                  <Text color="whisper" sans>
                    Kurs
                  </Text>
                </Stack>
                <Stack space={2}>
                  <TypewriterHeading>{currentDate}</TypewriterHeading>
                  <Divider size={2} color={alpha("contrast", 0.2)} />
                  <Text color="whisper" sans>
                    Datum
                  </Text>
                </Stack>
              </Stack>
            </Box>
          </Column>
        </Columns>
        <Grid columns={2} space={[3, 6]}>
          <Box
            p={[3, 6]}
            borderRadius="lg"
            sx={{
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: alpha("contrast", 0.2),
            }}
          >
            <Stack space={1}>
              <TypewriterHeading>{totalChapters}</TypewriterHeading>
              <Text sans color="whisper">
                Kapitel
              </Text>
            </Stack>
          </Box>
          <Box
            bg="letter"
            p={[3, 6]}
            borderRadius="lg"
            sx={{
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: alpha("contrast", 0.2),
            }}
          >
            <Stack space={1}>
              <TypewriterHeading>{totalTasks}</TypewriterHeading>
              <Text sans color="whisper">
                Aufgaben
              </Text>
            </Stack>
          </Box>
        </Grid>
      </Stack>
    </Box>
  )
}

CourseSummary.propTypes = {
  courseTitle: PropTypes.string,
  totalChapters: PropTypes.number,
  totalTasks: PropTypes.number,
}

CourseSummary.defaultProps = {
  courseTitle: "",
  totalChapters: 1,
  totalTasks: 1,
}

export default CourseSummary
