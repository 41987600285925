/** @jsx jsx */

import React, { useState } from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { jsx } from "theme-ui"
import Box from "./box"
import Modal from "./modal"
import { Link } from "gatsby"
import SquareButton from "./square-button"
import Stack from "./stack"
import Text from "./text"
import Heading from "./heading"

const CourseNavigation = ({ navigation, label }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const renderNavItem = (item, index) => {
    return item ? (
      <div key={`nav_${index}`}>
        <Link
          to={item.route}
          aria-label={item.title}
          activeClassName="active"
          sx={{
            display: "block",
            p: 3,
            ":hover, :focus, &.active": {
              color: "primary",
            },
          }}
        >
          <Text as="span" color="inherit" size={[2, 2, 3, 4]} sans bold>
            {item.title}
          </Text>
        </Link>

        {item.tasks.length > 0 && (
          <Stack as="ul" space={0}>
            {item.tasks
              .filter((task) => !task.invisible)
              .map((task, index) => (
                <Link
                  to={task.route}
                  key={`nav_task_${index}`}
                  aria-label={task.title}
                  activeClassName="active"
                  partiallyActive={true}
                  onClick={closeModal}
                  sx={{
                    display: "block",
                    py: 2,
                    pl: 6,
                    pr: 3,
                    ":hover, :focus, &.active": {
                      color: "primary",
                    },
                  }}
                >
                  <Text size={2} sans color="inherit">
                    {task.title}
                  </Text>
                </Link>
              ))}
          </Stack>
        )}
      </div>
    ) : null
  }

  return (
    <>
      <SquareButton onClick={openModal} icon="menu" aria-label={label}>
        {label}
      </SquareButton>
      <Modal isOpen={modalIsOpen} onClose={closeModal} contentLabel={label}>
        <Box
          as="nav"
          height="100%"
          display="flex"
          alignX="center"
          alignY="center"
        >
          <Stack>
            <Heading as="h1" level={5}>
              <Link
                to={navigation.route}
                onClick={closeModal}
                sx={{
                  ":hover, :focus, &.active": {
                    color: "primary",
                  },
                }}
              >
                {navigation.title}
              </Link>
            </Heading>
            <Stack as="ul" dividers space={0}>
              {navigation.chapters.map((item, index) =>
                renderNavItem(item, index)
              )}
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}

CourseNavigation.propTypes = {
  navigation: PropTypes.object,
  label: PropTypes.string,
}

CourseNavigation.defaultProps = {
  label: "Alle Aufgaben",
}

export default CourseNavigation
