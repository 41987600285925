import React from "react"
import Box from "./box"
import Breadcrumb from "../components/breadcrumb"
import Column from "./column"
import Columns from "./columns"
import Container from "./container"
import CourseNavigation from "./course-navigation"
import ThemeToggle from "./theme-toggle"

const CourseHeader = ({ navigation }) => {
  return (
    <Box position="relative" zIndex="10" px={[6, null, null, null, 12]} py={6}>
      <Container position="relative">
        <Box as="header">
          <Columns alignY="center" space={3} collapse={[true, true, false]}>
            <Column>
              <Breadcrumb
                linkToHome={false}
                items={[
                  {
                    title: "Hölderlinturm",
                    link: "/",
                  },
                  {
                    title: "Alle Kurse",
                    link: "/kurse",
                  },
                  {
                    title: navigation.title,
                    link: navigation.route,
                    partiallyActive: true,
                  },
                ]}
              />
            </Column>
            <Column width="content">
              <Columns
                alignY="center"
                alignX="space-between"
                space={6}
                reverse={[true, true, false]}
              >
                <Column width="content">
                  <ThemeToggle />
                </Column>
                <Column width="content">
                  <CourseNavigation navigation={navigation} />
                </Column>
              </Columns>
            </Column>
          </Columns>
        </Box>
      </Container>
    </Box>
  )
}

export default CourseHeader
