import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */
import { jsx } from "theme-ui"
import { useColorMode, useThemeUI } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"

import Box from "../components/box"
import { GatsbyImage } from "gatsby-plugin-image"
import { alpha } from "@theme-ui/color"

function renderBackdrop(src, theme, colorMode) {
  const backdropColor = "background"

  return src ? (
    <Box
      sx={{
        position: "relative",
        "@media (prefers-contrast: more)": {
          display: "none",
        },
      }}
    >
      <GatsbyImage
        image={src.node.childImageSharp.gatsbyImageData}
        alt=""
        sx={{ height: ["72", "72", "auto"], opacity: "0.15" }}
      />
      <Box
        position="absolute"
        left={0}
        height={(8, 16)}
        right={0}
        bottom={0}
        sx={{
          bg: "background",
        }}
      />
      <Box
        position="absolute"
        left={0}
        right={0}
        bottom={(8, 16)}
        pt="2/12"
        sx={{
          background: (theme) =>
            `linear-gradient(180deg,
                ${alpha(backdropColor, 0)(theme)},
                ${alpha(backdropColor, 0.0827)(theme)} 11.36%, 
                ${alpha(backdropColor, 0.17824)(theme)} 21.57%,
                ${alpha(backdropColor, 0.28281)(theme)} 30.81%, 
                ${alpha(backdropColor, 0.39259)(theme)} 39.26%, 
                ${alpha(backdropColor, 0.50376)(theme)} 47.09%,
                ${alpha(backdropColor, 0.6125)(theme)} 54.5%, 
                ${alpha(backdropColor, 0.71499)(theme)} 61.66%, 
                ${alpha(backdropColor, 0.80741)(theme)} 68.74%,
                ${alpha(backdropColor, 0.88594)(theme)} 75.94%,
                ${alpha(backdropColor, 0.94676)(theme)} 83.43%,
                ${alpha(backdropColor, 0.98605)(theme)} 91.39%,
                ${alpha(backdropColor, 1)(theme)}
            )`,
        }}
      />
      <Box
        position="absolute"
        width={["2/12"]}
        left={0}
        bottom={0}
        top={0}
        sx={{
          background: (theme) =>
            `linear-gradient(270deg,
              ${alpha(backdropColor, 0)(theme)},
              ${alpha(backdropColor, 0.01968)(theme)} 12.66%,
              ${alpha(backdropColor, 0.07407)(theme)} 23.38%,
              ${alpha(backdropColor, 0.15625)(theme)} 32.5%,
              ${alpha(backdropColor, 0.25926)(theme)} 40.37%,
              ${alpha(backdropColor, 0.37616)(theme)} 47.34%,
              ${alpha(backdropColor, 0.5)(theme)} 53.75%,
              ${alpha(backdropColor, 0.62384)(theme)} 59.95%,
              ${alpha(backdropColor, 0.74074)(theme)} 66.3%,
              ${alpha(backdropColor, 0.84375)(theme)} 73.13%,
              ${alpha(backdropColor, 0.92593)(theme)} 80.79%,
              ${alpha(backdropColor, 0.98032)(theme)} 89.63%,
              ${alpha(backdropColor, 1)(theme)}
            )`,
        }}
      />
      <Box
        position="absolute"
        width={"2/12"}
        right={0}
        bottom={0}
        top={0}
        sx={{
          background: (theme) =>
            `linear-gradient(90deg, 
                ${alpha(backdropColor, 0)(theme)},
                ${alpha(backdropColor, 0.01968)(theme)} 12.66%,
                ${alpha(backdropColor, 0.07407)(theme)} 23.38%, 
                ${alpha(backdropColor, 0.15625)(theme)} 32.5%, 
                ${alpha(backdropColor, 0.25926)(theme)} 40.37%, 
                ${alpha(backdropColor, 0.37616)(theme)} 47.34%, 
                ${alpha(backdropColor, 0.5)(theme)} 53.75%, 
                ${alpha(backdropColor, 0.62384)(theme)} 59.95%, 
                ${alpha(backdropColor, 0.74074)(theme)} 66.3%, 
                ${alpha(backdropColor, 0.84375)(theme)} 73.13%, 
                ${alpha(backdropColor, 0.92593)(theme)} 80.79%, 
                ${alpha(backdropColor, 0.98032)(theme)} 89.63%,
                ${alpha(backdropColor, 1)(theme)}
            )`,
        }}
      />
    </Box>
  ) : null
}

const Backdrop = (props) => {
  const context = useThemeUI()
  const [colorMode] = useColorMode()
  const { theme } = context

  return (
    <StaticQuery
      query={graphql`
        {
          backdrops: allFile(
            filter: {
              extension: { regex: "/jpeg|jpg|png|gif/" }
              relativePath: { regex: "/backdrops/" }
            }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  gatsbyImageData(
                    transformOptions: { grayscale: true }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      `}
      render={({ backdrops }) =>
        renderBackdrop(
          backdrops.edges.find(
            (backdrop) =>
              backdrop.node.relativePath === `backdrops/${props.src}.jpg`
          ),
          theme,
          colorMode
        )
      }
    />
  )
}

Backdrop.propTypes = {}

Backdrop.defaultProps = {
  src: "default",
}

export default Backdrop
