/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Box from "../components/box"
import Container from "../components/container"
import CourseHeader from "../components/course-header"
import Preflight from "../gatsby-plugin-theme-ui/preflight"
import SkipContent from "../components/skip-content"
import Backdrop from "../components/backdrop"
import Stack from "../components/stack"

const LayoutCourse = ({
  as,
  children,
  footer,
  progress,
  navigation,
  ...restProps
}) => {
  return (
    <>
      <Preflight />
      <SkipContent />
      <Box>
        <Container position="relative">
          <Box position="absolute" top={0} left={0} right={0}>
            <Backdrop src="workshops" />
          </Box>
        </Container>
        <CourseHeader navigation={navigation} progress={progress} />
        <Container maxWidth="5xl">
          <Box py={[0, 0, 12, 16]} as="main" id="inhalt">
            <Box
              as={as}
              bg="background"
              p={[6, 8, 12, 16]}
              sx={{ position: "relative", zIndex: "10" }}
              {...restProps}
            >
              <Stack>
                <Box>{children}</Box>
                {footer && <Box as="footer">{footer}</Box>}
              </Stack>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}

LayoutCourse.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  navigation: PropTypes.object,
}

export default LayoutCourse
