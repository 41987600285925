import React from "react"
import { navigate } from "gatsby"
import { postVote } from "../../../../api"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../store/courses"
import LayoutCourse from "../../../../layouts/course"

import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterBool from "../../../../components/course-footer-bool"
import Paragraph from "../../../../components/paragraph"
import CourseSummary from "../../../../components/course-summary"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const progress = getCourseProgress({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterBool
          title="Hat dir der Kurs gefallen?"
          onSubmit={async (positive) => {
            try {
              await postVote({
                title: "haelfte-des-lebens",
                upvoted: positive,
              })
            } catch (error) {}
            navigate("/kurse/haelfte-des-lebens/feedback", {
              state: { positive },
            })
          }}
        />
      }
      navigation={navigation}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Super! Du hast es geschafft.
          </Heading>
          <CourseSummary {...progress} />
          <Paragraph>
            In diesem Kapitel hast du Einblick in Hölderlins Arbeit an seinen
            Manuskripten erhalten und die Keimwörter inspiziert, aus denen das
            Gedicht ›Hälfte des Lebens‹ hervorgegangen ist.
          </Paragraph>
          <Paragraph>
            Beim Lesen von Hölderlins Briefen an Freunde und Verwandte hast du
            festgestellt, dass er immer wieder mit sich selbst und seinem Platz
            in der Welt gehadert hat. Daraufhin bist du den Stimmungen im
            Gedicht nachgegangen und hast bemerkt, dass zwischen der 1. und 2.
            Strophen ein harter Stimmungswandel erfolgt. Aus diesem
            Zwischenraum, der die Mitte des Gedichts und – übertragen auf das
            Bild der Lebenshälften – zugleich eine Lebensmitte markiert, blickt
            das lyrische Ich zurück auf eine erfüllte, glückliche Vergangenheit
            und wendet den Blick daraufhin skeptisch in die Zukunft.
          </Paragraph>
          <Paragraph>
            ›Nachtgesänge‹ hat Hölderlin ›Hälfte des Lebens‹ und die anderen
            Gedichte genannt, die er in Wilmanns Taschenbuch drucken ließ. Bei
            genauerer Betrachtung der Seiten in der Erstveröffentlichung wurde
            deutsch, dass Hölderlin die Abfolge dieser Gedichte und ihr
            Zusammenspiel genau konstruiert und nichts dem Zufall überlassen
            hat.
          </Paragraph>
          <Paragraph>
            Dennoch wurden die Gedichte seinerzeit von der Kritik ziemlich
            verrissen. Deine eigene Bewertung des Gedichts hast du zuletzt in
            Form einer Rezension zu Papier gebracht.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
